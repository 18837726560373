import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filter_component = _resolveComponent("filter-component")!
  const _component_http_top_height_view = _resolveComponent("http-top-height-view")!
  const _component_page = _resolveComponent("page")!

  return (_openBlock(), _createBlock(_component_page, {
    name: "Топ Http с максимальным количеством вызовов",
    class: "top-heigh-methods-load",
    selectSource: false
  }, {
    toolbar: _withCtx(() => [
      _createVNode(_component_filter_component, {
        filter: _ctx.filterModel,
        "onUpdate:filter": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterModel) = $event)),
        onFilterData: _ctx.filterData,
        selectInterval: false,
        methods: false,
        logsType: false,
        logsLevel: false,
        url: false,
        requestId: false,
        loggerName: false,
        shortMessage: false
      }, null, 8, ["filter", "onFilterData"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_http_top_height_view, {
        filter: _ctx.filterModel,
        ref: "page"
      }, null, 8, ["filter"])
    ]),
    _: 1
  }))
}