import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "logs-view"
}
const _hoisted_2 = {
  key: 0,
  class: "row mt-2 justify-content-center"
}
const _hoisted_3 = {
  key: 1,
  class: "row mt-2 justify-content-center"
}
const _hoisted_4 = {
  key: 2,
  class: "row mt-2 justify-content-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tirscript3_loading = _resolveComponent("tirscript3-loading")!
  const _component_http_top_view_table = _resolveComponent("http-top-view-table")!

  return (_ctx.filter)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_tirscript3_loading, { loading: _ctx.isLoading }, null, 8, ["loading"]),
        _createElementVNode("div", null, [
          _createVNode(_component_http_top_view_table, {
            items: _ctx.itemsTopLongRequest,
            pages: _ctx.pageTopLongRequest,
            title: "Топ долгих запросов (Максимальные по времени)",
            onChangePage: _ctx.getItemsTopLongRequest
          }, null, 8, ["items", "pages", "onChangePage"])
        ]),
        (!_ctx.error1.isSuccess)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.error1.errorMessage), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createVNode(_component_http_top_view_table, {
            items: _ctx.itemsTopFatRequests,
            pages: _ctx.pageTopFatRequests,
            title: "Топ больших запросов (Максимальные по объему)",
            onChangePage: _ctx.getItemsTopLongRequests
          }, null, 8, ["items", "pages", "onChangePage"])
        ]),
        (!_ctx.error2.isSuccess)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.error2.errorMessage), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createVNode(_component_http_top_view_table, {
            items: _ctx.itemsTopFatResponses,
            pages: _ctx.pageTopFatResponses,
            title: "Топ больших ответов (Максимальные по объему)",
            onChangePage: _ctx.getItemsTopFatResponses
          }, null, 8, ["items", "pages", "onChangePage"])
        ]),
        (!_ctx.error3.isSuccess)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.error3.errorMessage), 1))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}