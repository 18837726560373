
import { Options, Vue, Watch } from "vue-property-decorator";

import Page from "@views/page/components/page.vue";

import ViewFilter from "@views/page/view-filter.vue";
import FilterModel from "../components/filter-model";
import PageRequest from "@/dataSource/api/pagination-helper/lib/PageRequest";
import HttpTopHeightView from "./http-top-height-view.vue"
@Options<TopHeightMethodsLoadPage>({
  name: "TopHeightMethodsLoad",
  components: {
    Page,
    ViewFilter,
    HttpTopHeightView,
  },
})
export default class TopHeightMethodsLoadPage extends Vue {
  pagination: PageRequest;
  filterModel: FilterModel = new FilterModel();

  filterData() {
    (this.$refs.page as HttpTopHeightView).onFilterChange();
  }
}
