
import moment from "moment";
import { Options, Vue, Prop } from "vue-property-decorator";
// import TopEndpointLogItemExtends from "../models/TopEndpointLogItemExtends";
import PageResponse from "@/dataSource/api/pagination-helper/lib/PageResponse";
import EndpointLogsItemResponse from "@/dataSource/api/logger-micro-service/services/models/response/log-getter-service/EndpointLogsItemResponse";
import EnumLogLevel from "@/dataSource/api/tirscript/logger-micro-service/enums/EnumLogLevel";
import TopEndpointLogItemExtends from "../methods-of-logs/models/TopEndpointLogItemExtends";
import HttpMethodEnum from "@/dataSource/api/tirscript/logger-micro-service/enums/HttpMethodEnum";

@Options({
  name: "HttpTopViewTable",
})
export default class HttpTopViewTable extends Vue {
  // здесь инициализируются prop
  @Prop({ default: [] }) items: TopEndpointLogItemExtends[] = [];
  @Prop({ default: null }) pages: PageResponse = null;
  @Prop({ default: "" }) title: string;
  take = 10;
  isActive = false;
  type(item: EndpointLogsItemResponse) {
    switch (item.LevelId) {
      case EnumLogLevel.Error:
        return "error";
      case EnumLogLevel.Warn:
        return "warn";
      default:
        return "default";
    }
  }
  enumMethod = HttpMethodEnum;
  onPaginationChanged(page: number) {
    this.$emit("changePage", page);
  }
  getIntervalTime(dateStart: Date, dateEnd: Date): number {
    return new Date(dateEnd).getTime() - new Date(dateStart).getTime();
  }
  onReadmore(id: number) {
    this.$emit("editEndpointId", id);
    this.items.find((item) => (item as any).RequestId == id.toString());
    this.$router.push({ name: "request-datail", params: { id: id } });
  }
  m(value: Date) {
    return moment(value).format("DD.MM.YYYY hh:mm");
  }
}
